import { ITheme, ITooltipHostStyles, Stack, Text, TooltipHost } from '@fluentui/react';
import { IC } from 'components/SVGIcon';
import { IRoomAction } from 'features/Rooms/RoomList/helpers/roomTypes';
import { IRoomResource } from 'kits/apiKit3/legacy';
import IconKit from 'kits/IconKit';
import { More, SvgLockNo, SvgLockYes } from 'kits/IconKit2';
import SvgVisitors from 'kits/IconKit2/Visitors';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { useSideBarDraggableWidth, useWindowWidth } from 'shared-state/display-options/hooks';
import 'styles/RoomList.css';
import { RoomListMoreButton } from '../MoreButton/MoreButton';

const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

export const RoomStatusIcon: React.FC<{ variant: 'dark' | 'green' | 'red'; toolTip?: string; children?: React.ReactNode }> = ({
    children,
    toolTip,
    variant
}) => {
    const { smallDevice } = useWindowWidth();
    if (!toolTip)
        return (
            <Stack style={{ padding: 4 }}>
                <IC size={18} variant={variant}>
                    {children}
                </IC>
            </Stack>
        );

    return (
        <TooltipHost content={smallDevice ? '' : toolTip} calloutProps={calloutProps} styles={hostStyles}>
            <Stack style={{ padding: 4 }}>
                <IC size={18} variant={variant}>
                    {children}
                </IC>
            </Stack>
        </TooltipHost>
    );
};

export interface IListedRoomHeaderProps {
    onDoubleClick: () => void;
    isRoomEnteranceAllowedForUser: () => boolean;
    theme: ITheme;
    isVisitorInArea?: boolean;
    room: IRoomResource;
    isSelected?: boolean;
    actions: IRoomAction[];
    isPersonalRoom: boolean;
    isUserNotAlone: boolean;
}

const ListedRoomHeaderView: React.FC<IListedRoomHeaderProps> = ({
    onDoubleClick,
    isRoomEnteranceAllowedForUser,
    theme,
    isVisitorInArea,
    room,
    isSelected,
    actions,
    isPersonalRoom,
    isUserNotAlone
}) => {
    const { smallDevice } = useWindowWidth();
    const [sideBarDraggableWidth] = useSideBarDraggableWidth();
    const decreasedSideBarDraggableWidth = sideBarDraggableWidth - (room.attributes.openForVisitors ? 180 : 150);
    const decreasedWindowInnerWidth = window.innerWidth - 180;
    const decreasedSideBarDraggableWidthForPersonalRoom = sideBarDraggableWidth - 180;
    const decreasedWindowInnerWidthForPersonalRoom = window.innerWidth - 200;
    const personalRoomHelperString = room?.attributes.displayName.split(IvicosStrings.personalRoomHelper)[0];
    const personalRoomTitleString = IvicosStrings.personalRoomTitle;
    const maxWidth = smallDevice ? decreasedWindowInnerWidthForPersonalRoom : sideBarDraggableWidth ? decreasedSideBarDraggableWidthForPersonalRoom : 160;

    if (room?.attributes.private && !room.attributes.creatorId)
        return (
            <>
                {isPersonalRoom && isUserNotAlone ? (
                    <Stack
                        key="header"
                        horizontal
                        verticalAlign="center"
                        style={{
                            padding: '3px 3px',
                            borderRadius: '4px 4px 0px 0px',
                            backgroundColor: isSelected ? theme.palette.themeLight : theme.palette.white,
                            color: theme.palette.black
                        }}
                    >
                        <Text
                            className="shorten-text"
                            style={{
                                margin: '3px 0 3px 8px',
                                maxWidth
                            }}
                        >
                            {personalRoomHelperString}
                        </Text>
                        <Text style={{ marginRight: '8px' }}>{personalRoomTitleString}</Text>
                    </Stack>
                ) : (
                    // hidden stack to check username's validity
                    <Stack aria-label="listed-personal-room-header" style={{ display: 'none' }}>
                        <Text>{personalRoomHelperString}</Text>
                        <Text>{personalRoomTitleString}</Text>
                    </Stack>
                )}
            </>
        );

    return (
        <Stack
            key={'header'}
            horizontal
            verticalAlign="center"
            styles={{
                root: {
                    opacity: !isRoomEnteranceAllowedForUser() ? '0.4' : '1',
                    width: '100%',
                    cursor: 'pointer',
                    background: isSelected ? theme.palette.themeLight : theme.palette.white,
                    ':hover': {
                        backgroundColor: theme.palette.themeLight
                    },
                    ':active': {
                        backgroundColor: theme.palette.themeTertiary
                    }
                }
            }}
        >
            <Stack
                horizontal
                className="padding--small"
                grow
                verticalAlign="center"
                onDoubleClick={() => isRoomEnteranceAllowedForUser() && onDoubleClick && onDoubleClick()}
                style={{ height: '100%', minHeight: '100%' }}
            >
                <Stack disableShrink={true} key={'symbol'} className="roomlist_room_symbol-wrapper" verticalAlign="center" horizontalAlign="center">
                    <IconKit.Icon icon={IconKit.getIcon('Rooms', room?.attributes.symbol)} size={24} />
                </Stack>
                <Stack key={'displayName'} grow style={{ overflow: 'hidden' }}>
                    <Text
                        title={room?.attributes.displayName}
                        className="shorten-text"
                        style={{
                            maxWidth: smallDevice ? decreasedWindowInnerWidth : sideBarDraggableWidth ? decreasedSideBarDraggableWidth : 160,
                            color: theme.palette.black
                        }}
                    >
                        {room?.attributes.displayName} {isRoomEnteranceAllowedForUser()}
                    </Text>
                </Stack>
            </Stack>

            <Stack className="padding--small" horizontal verticalAlign="center">
                {room?.attributes.openForVisitors && (!room.attributes.whitelist || room.attributes.whitelist.length == 0) && (
                    <RoomStatusIcon toolTip={IvicosStrings.openForVisitorsTitle} variant={isVisitorInArea ? 'green' : 'dark'}>
                        <SvgVisitors />
                    </RoomStatusIcon>
                )}

                {!isRoomEnteranceAllowedForUser() && (
                    <Stack style={{ marginRight: 2.2 }}>
                        <RoomStatusIcon toolTip={IvicosStrings.noAccessToRoom} variant="dark">
                            <SvgLockNo />
                        </RoomStatusIcon>
                    </Stack>
                )}
                {isRoomEnteranceAllowedForUser() && room?.attributes.private && room.attributes.creatorId && (
                    <RoomStatusIcon toolTip={IvicosStrings.noAccessToRoom} variant="green">
                        <SvgLockYes />
                    </RoomStatusIcon>
                )}
                {isRoomEnteranceAllowedForUser() ? (
                    <RoomListMoreButton actions={actions} />
                ) : (
                    <RoomStatusIcon variant="dark">
                        <More />
                    </RoomStatusIcon>
                )}
            </Stack>
        </Stack>
    );
};

export default ListedRoomHeaderView;
