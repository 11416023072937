import { DefaultButton, Stack, Text, SearchBox } from '@fluentui/react';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';

export interface IInputFieldProps {
    onSubmit: (phrase: string) => void;
}

export const InputField: React.FC<IInputFieldProps> = (props) => {
    return <SearchBox className="sherloq-suggestion-input" placeholder={IvicosStrings.sherloqInputPlaceholder} onSearch={props.onSubmit} />;
};

export interface ISuggestionsProps {
    onSubmit: (phrase: string) => void;
    suggestedPrompts: string[];
}

export const Suggestions: React.FC<ISuggestionsProps> = (props) => {
    const buttonStyle: React.CSSProperties = {
        margin: '4px 4px 4px 0',
        borderRadius: '16px',
        maxWidth: '100%',
        overflow: 'hidden'
    };

    const buttonTextStyle: React.CSSProperties = {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    };

    return (
        <div style={{ width: '100%' }}>
            {props.suggestedPrompts.map((phrase, i) => (
                <DefaultButton key={i} style={buttonStyle} onClick={() => props.onSubmit(phrase)} className="sherloq-suggestion">
                    <span style={buttonTextStyle}>{phrase}</span>
                </DefaultButton>
            ))}
        </div>
    );
};
