import WidgetContainer from './Widget/WidgetContainer';
import React from 'react';
import { WidgetDefinition } from './Widget/Widget';

export interface PersonalDashboardProps {
    backgroundColor: string;
    widgets: WidgetDefinition[];
}

const PersonalDashboardView: React.FC<PersonalDashboardProps> = (props) => {
    const dashboardStyle: React.CSSProperties = {
        backgroundColor: props.backgroundColor,
        width: '100%',
        height: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridTemplateRows: 'repeat(12, 1fr)',
        gap: '16px',
        padding: '16px',
        boxSizing: 'border-box'
    };

    return (
        <div style={dashboardStyle}>
            {props.widgets.map((widget, i) => (
                <WidgetContainer key={i} {...widget} />
            ))}
        </div>
    );
};
export default PersonalDashboardView;
