import { getTheme, Text } from '@fluentui/react';
import React from 'react';

export interface WidgetContainerProps {
    x: number;
    y: number;
    height: number;
    width: number;
    children: React.ReactNode;
}

const WidgetContainerView: React.FC<WidgetContainerProps> = (props) => {
    const theme = getTheme();
    const { x, y, width, height } = props;
    const containerStyle: React.CSSProperties = {
        gridColumnStart: x + 1,
        gridColumnEnd: `span ${width}`,
        gridRowStart: y + 1,
        gridRowEnd: `span ${height}`,
        backgroundColor: theme.palette.white,
        borderRadius: theme.effects.roundedCorner4,
        boxShadow: theme.effects.elevation4,
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        overflow: 'auto'
    };

    return (
        <div className="widget" style={containerStyle}>
            <div style={{ flexGrow: '1' }}>{props.children}</div>
        </div>
    );
};

export default WidgetContainerView;
