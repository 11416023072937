import { IvicosColors } from 'kits/colorKit';
import React from 'react';
import PersonalDashboardView from './PersonalDashboard.view';
import { WidgetDefinition } from './Widget/Widget';

const PersonalDashboard: React.FC = (props) => {
    const widgets: WidgetDefinition[] = [
        {
            x: 0,
            y: 0,
            height: 4,
            width: 5,
            type: 'sherloq'
        }

        //! kept it for future reference
        // {
        //     x: 6,
        //     y: 0,
        //     height: 4,
        //     width: 2,
        //     type: 'sherloq'
        // },
        // {
        //     x: 0,
        //     y: 4,
        //     height: 4,
        //     width: 8,
        //     type: 'sherloq'
        // },
        // {
        //     x: 8,
        //     y: 4,
        //     height: 2,
        //     width: 4,
        //     type: 'sherloq'
        // }
    ];
    return <PersonalDashboardView backgroundColor={IvicosColors.Dunkelblau} widgets={widgets}></PersonalDashboardView>;
};
export default PersonalDashboard;
