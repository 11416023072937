import React, { useState } from 'react';
import WidgetContainerView from './WidgetContainer.view';
import { WidgetDefinition } from './Widget';
import { SherloqWidget } from '../../Sherloq/Widget';
import { TextWidget } from './TextWidget.tsx/TextWidget';

function renderWidgetBody(settings: WidgetDefinition, props): React.ReactNode {
    switch (settings.type) {
        case 'sherloq':
            return <SherloqWidget {...props}></SherloqWidget>;
        case 'text':
            return <TextWidget {...props}></TextWidget>;
        default:
            return <TextWidget {...props}></TextWidget>;
    }
}

const WidgetContainer: React.FC<WidgetDefinition> = (props) => {
    const [title, setTitle] = useState('');

    return <WidgetContainerView {...props}>{renderWidgetBody(props, { setTitle: setTitle })}</WidgetContainerView>;
};

export default WidgetContainer;
