import { getTheme, ITheme, ITooltipHostStyles, Stack, TooltipHost } from '@fluentui/react';
import { IC, ICButton } from 'components/SVGIcon';
import { IRoomAction } from 'features/Rooms/RoomList/helpers/roomTypes';
import { IRoomResource } from 'kits/apiKit3/legacy';
import IvicosStrings from 'kits/language/stringKit';
import React from 'react';
import { IProfile } from 'shared-state/identity/types';
import 'styles/RoomList.css';
import ListedRoomHeader from '../ListedRoomHeader/ListedRoomHeader';
import UserFacepile from '../UserFacepile/UserFacepile';
import { useWindowWidth } from 'shared-state/display-options/hooks';

const calloutProps = { gapSpace: 0 };
const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

export const RoomStatusIcon: React.FC<{ variant: 'dark' | 'green' | 'red'; toolTip?: string; children?: React.ReactNode }> = ({
    children,
    toolTip,
    variant
}) => {
    const { smallDevice } = useWindowWidth();
    if (!toolTip)
        return (
            <Stack style={{ padding: 4 }}>
                <IC size={18} variant={variant}>
                    {children}
                </IC>
            </Stack>
        );

    return (
        <TooltipHost content={smallDevice ? '' : toolTip} calloutProps={calloutProps} styles={hostStyles}>
            <Stack style={{ padding: 4 }}>
                <IC size={18} variant={variant}>
                    {children}
                </IC>
            </Stack>
        </TooltipHost>
    );
};
export interface IListedRoomsViewProps {
    roomId: string;
    isSelected: boolean;
    usersInRoom: IProfile[];
    actions: IRoomAction[];
    rootStyle: string;
    room: IRoomResource | undefined;
    onDoubleClick: () => void;
    isRoomEnteranceAllowedForUser: () => boolean;
    theme: ITheme;
    userId: string | undefined;
    isVisitorInArea?: boolean;
    isPersonalRoom: boolean;
    isUserNotAlone: boolean;
}

const ListedRoomView: React.FC<IListedRoomsViewProps> = ({
    isSelected,
    usersInRoom,
    actions,
    rootStyle,
    room,
    onDoubleClick,
    isVisitorInArea,
    isRoomEnteranceAllowedForUser,
    isPersonalRoom,
    isUserNotAlone
}) => {
    const theme = getTheme();
    if (!room) return <></>;
    else
        return (
            <Stack className={rootStyle}>
                <ListedRoomHeader
                    room={room}
                    actions={actions}
                    isSelected={isSelected}
                    isVisitorInArea={isVisitorInArea}
                    theme={theme}
                    isRoomEnteranceAllowedForUser={isRoomEnteranceAllowedForUser}
                    onDoubleClick={onDoubleClick}
                    isPersonalRoom={isPersonalRoom}
                    isUserNotAlone={isUserNotAlone}
                />
                <Stack
                    horizontal
                    styles={{
                        root: {
                            background: isSelected ? theme.palette.themeLighter : theme.palette.neutralLighter,
                            padding: 8,
                            minHeight: 32,
                            display: usersInRoom.length > 0 ? 'inherit' : 'none'
                        }
                    }}
                    horizontalAlign="space-between"
                    tokens={{ childrenGap: 8 }}
                >
                    <UserFacepile users={usersInRoom} panelTitle={IvicosStrings.usersIn + room.attributes.displayName}></UserFacepile>
                    <Stack horizontal tokens={{ childrenGap: 8 }}>
                        {actions
                            .filter((a: any) => a.hasPriority && !a.isDisabled)
                            .map((a: any) => {
                                if (!a) return <></>;
                                return (
                                    <ICButton key={a.key} title={a.title} size={42} onClick={() => a.action()}>
                                        {a.getSymbol && a.getSymbol()}
                                    </ICButton>
                                );
                            })}
                    </Stack>
                </Stack>
            </Stack>
        );
};

export default ListedRoomView;
