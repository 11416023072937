import { ITheme } from '@fluentui/react';
import { IRoomAction } from 'features/Rooms/RoomList/helpers/roomTypes';
import { IRoomResource } from 'kits/apiKit3/legacy';
import React from 'react';
import 'styles/RoomList.css';
import ListedRoomHeaderView from './ListedRoomHeader.view';

export interface IListedRoomHeaderProps {
    room: IRoomResource;
    isSelected: boolean;
    actions: IRoomAction[];
    onDoubleClick: () => void;
    isRoomEnteranceAllowedForUser: () => boolean;
    theme: ITheme;
    isVisitorInArea?: boolean;
    isPersonalRoom: boolean;
    isUserNotAlone: boolean;
}

const ListedRoomHeader: React.FC<IListedRoomHeaderProps> = (props) => {
    return <ListedRoomHeaderView {...props} />;
};

export default ListedRoomHeader;
