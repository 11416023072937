import { Stack, Text, Image, Shimmer } from '@fluentui/react';
import sherloqImage from 'media/img/SHERLOQ.png';
import { IC } from 'components/SVGIcon';
import { Close } from 'kits/IconKit2';
import IvicosStrings from 'kits/language/stringKit';
import React, { useEffect, useState } from 'react';
import { InputField, Suggestions } from './children/Input';
import { getSuggestedPrompts, makeRequest } from './Actions';

interface IChatHistoryItem {
    prompt: string;
    isLoading: boolean;
    hasError: boolean;
    answer: string;
}

interface ISherloqPanelProps {
    isVisible: boolean;
    executeCommand: (command: string, data?: any) => void;
}

const SherloqPanel: React.FC<ISherloqPanelProps> = (props) => {
    if (!props.isVisible) return;
    const [history, setHistory] = useState<IChatHistoryItem[]>([]);
    const [suggestedPrompts, setSuggestedPrompts] = useState<string[]>([]);

    useEffect(() => {
        const fetchPrompts = async () => {
            const prompts = await getSuggestedPrompts();
            setSuggestedPrompts(prompts);
        };

        fetchPrompts();
    }, []);

    async function handleSubmit(submittedPrompt: string) {
        const newItem: IChatHistoryItem = {
            prompt: submittedPrompt,
            isLoading: true,
            hasError: false,
            answer: ''
        };
        const index = history.length;
        setHistory((prevItems) => [...prevItems, newItem]);
        try {
            const response = await makeRequest(submittedPrompt);
            newItem.answer = response;
        } catch (e: any) {
            console.error(e);
            newItem.hasError = true;
        } finally {
            newItem.isLoading = false;

            setHistory((prevItems) => {
                const updatedItems = [...prevItems];
                updatedItems[index] = newItem;
                return updatedItems;
            });
        }
    }

    return (
        <PanelWrapper executeCommand={props.executeCommand}>
            <Stack.Item grow={2} style={{ overflowY: 'auto' }}>
                {history.length == 0 ? (
                    <Image src={sherloqImage} width={'100%'} />
                ) : (
                    history.map((item) => <ChatElement key={item.prompt} {...item}></ChatElement>)
                )}
            </Stack.Item>
            {history.length == 0 ? <Suggestions onSubmit={handleSubmit} suggestedPrompts={suggestedPrompts} /> : null}
            <InputField onSubmit={handleSubmit}></InputField>
            <Text style={{ textAlign: 'center' }}>Sherloq kann auch mal Fehler machen. Überprüfen Sie bitte wichtige Informationen über die Quellen.</Text>
        </PanelWrapper>
    );
};
export default React.memo(SherloqPanel);

interface PanelProps {
    children: React.ReactNode;
    executeCommand: (command: string, data?: any) => void;
}

const PanelWrapper: React.FC<PanelProps> = (props) => {
    return (
        <Stack
            style={{
                padding: 16,
                background: 'white',
                width: 430,
                maxHeight: '100%'
            }}
            tokens={{ childrenGap: 8 }}
        >
            <Stack horizontal style={{ alignItems: 'center', marginBottom: 16 }}>
                <Stack grow style={{ color: 'black' }}>
                    <Text variant="xLarge">{IvicosStrings.sherloqTitleAskSherloq}</Text>
                </Stack>
                <Stack
                    onClick={() => {
                        props.executeCommand('toggleSherloq');
                    }}
                    className="pressable"
                >
                    <IC size={24} variant="dark">
                        <Close />
                    </IC>
                </Stack>
            </Stack>
            {props.children}
        </Stack>
    );
};

const ChatElement: React.FC<IChatHistoryItem> = (props) => {
    return (
        <Stack tokens={{ childrenGap: '8px' }} style={{ marginBottom: '16px' }}>
            <Text style={{ fontWeight: 'bold' }}>{props.prompt}</Text>
            {props.isLoading ? (
                <Stack>
                    <Shimmer style={{ marginBottom: '8px' }} />
                    <Shimmer style={{ marginBottom: '8px' }} />
                    <Shimmer style={{ marginBottom: '8px' }} />
                    <Shimmer width="70%" />
                </Stack>
            ) : props.hasError ? (
                <Text>{IvicosStrings.sherloqLabelError}</Text>
            ) : (
                <Text>{props.answer}</Text>
            )}
        </Stack>
    );
};
