import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Stack, Text } from '@fluentui/react';
import React from 'react';
import IvicosStrings from 'kits/language/stringKit';
import { idpService } from 'services/api/identity-provider.service';

interface ISherloqDeactivationModalProps {
    isSherloqDeactivationModalVisible: boolean;
    setIsSherloqDeactivationModalVisible: (value: boolean) => void;
    setIsSherloqActive: (value: boolean) => void;
}

export const SherloqDeactivationModal: React.FC<ISherloqDeactivationModalProps> = ({
    isSherloqDeactivationModalVisible,
    setIsSherloqDeactivationModalVisible,
    setIsSherloqActive
}) => {
    const dialogContentProps = {
        type: DialogType.normal,
        title: IvicosStrings.deactivateSherloq,
        closeButtonAriaLabel: 'Close'
    };

    return (
        <Dialog
            hidden={!isSherloqDeactivationModalVisible}
            onDismiss={() => setIsSherloqDeactivationModalVisible(false)}
            dialogContentProps={dialogContentProps}
            modalProps={{
                isBlocking: true
            }}
        >
            <Stack tokens={{ childrenGap: 16 }} style={{ marginBottom: 35 }}>
                <Text>{IvicosStrings.sherloqDeactivateWarning}</Text>
            </Stack>
            <DialogFooter>
                <Stack horizontal tokens={{ childrenGap: 16 }}>
                    <DefaultButton onClick={() => setIsSherloqDeactivationModalVisible(false)} text={IvicosStrings.cancelActionTitle} />
                    <PrimaryButton
                        onClick={async () => {
                            const { is_sherloq_active } = await idpService.deactivateSherloq();
                            setIsSherloqActive(is_sherloq_active);
                            setIsSherloqDeactivationModalVisible(false);
                        }}
                        text={IvicosStrings.confirmActionTitle}
                    />
                </Stack>
            </DialogFooter>
        </Dialog>
    );
};
