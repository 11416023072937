import { idpService } from 'services/api/identity-provider.service';

const suggestedPrompts = [
    'Wer ist der Bürgermeister von Freiburg?',
    'Wer bezahlt die Kindergartenplätze in Freiburg?',
    'Wie groß ist der schönste Springbrunnen der Stadt?'
];

export async function makeRequest(prompt: string): Promise<string> {
    return idpService.makePredictRequest(prompt);
}

export async function getSuggestedPrompts(): Promise<string[]> {
    const sherloqPrompts = localStorage.getItem('sherloqPrompts');
    if (sherloqPrompts) {
        return JSON.parse(sherloqPrompts);
    }
    return suggestedPrompts;
}
