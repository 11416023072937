import { IconButton, Panel, Stack, Toggle, Text } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useBoolean, useToggle } from 'react-use';
import SherloqIcon from './Icon';
import IvicosStrings from 'kits/language/stringKit';
import { ISherloqFormFields, SherloqSettingsForm } from './SherloqSettingsForm';
import { idpService } from 'services/api/identity-provider.service';
import { SherloqDeactivationModal } from './SherloqDeactivationModal';

export const SherloqSettings: React.FC = () => {
    const [isSherloqSettingsLoaded, setIsSherloqSettingsLoaded] = useBoolean(false);
    const [isSherloqSettingPanelOpen, setIsSherloqSettingPanelOpen] = useBoolean(false);
    const [sherloqEnabled, toggleSherloqEnabled] = useToggle(false);
    const [isSherloqActive, setIsSherloqActive] = useBoolean(false);
    const [isSherloqDeactivationModalVisible, setIsSherloqDeactivationModalVisible] = useBoolean(false);
    const [sherloqSettingsFormData, setSherloqSettingsFormData] = useState<ISherloqFormFields>({
        clientId: '',
        clientSecret: '',
        model: '',
        customer: ''
    });
    const [listOfExamplePrompts, setListOfExamplePrompts] = useState<string[]>([]);

    useEffect(() => {
        const getSherloqSettings = async () => {
            try {
                const { sherloq_settings, is_sherloq_active } = await idpService.getSherloqSettings();

                if (is_sherloq_active) {
                    const { clientId, encrypted_clientSecret, model, customer, example_prompts } = sherloq_settings;
                    toggleSherloqEnabled(true);
                    setIsSherloqActive(true);
                    setSherloqSettingsFormData({
                        clientId,
                        clientSecret: encrypted_clientSecret,
                        model,
                        customer
                    });
                    setListOfExamplePrompts(example_prompts);
                }
            } catch (error) {
                console.error('Error fetching Sherloq settings:', error);
            } finally {
                // Small delay to prevent UI flicker
                setTimeout(() => setIsSherloqSettingsLoaded(true), 300);
            }
        };

        if (!isSherloqSettingPanelOpen) {
            getSherloqSettings();
        }
    }, [isSherloqSettingPanelOpen]);

    useEffect(() => {
        if (!sherloqEnabled && isSherloqActive) {
            setIsSherloqDeactivationModalVisible(true);
        }
    }, [sherloqEnabled]);

    useEffect(() => {
        if (!isSherloqDeactivationModalVisible && isSherloqActive) {
            toggleSherloqEnabled(true);
        }

        if (!isSherloqDeactivationModalVisible && !isSherloqActive && !sherloqEnabled) {
            setSherloqSettingsFormData({
                clientId: '',
                clientSecret: '',
                model: '',
                customer: ''
            });
            setListOfExamplePrompts([]);
        }
    }, [isSherloqDeactivationModalVisible]);

    const handleSherloqSettingPanel = (value: boolean) => {
        setIsSherloqSettingPanelOpen(value);
    };

    return (
        <div>
            {isSherloqSettingsLoaded ? (
                <>
                    <Stack horizontal tokens={{ childrenGap: 16 }} verticalAlign="center">
                        <Toggle checked={sherloqEnabled} onChange={toggleSherloqEnabled} />
                        <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center" style={{ marginTop: -10 }}>
                            <SherloqIcon color="#201F1E" />
                            <Text variant="xLarge">SHERLOQ</Text>
                        </Stack>
                        {sherloqEnabled && (
                            <Stack horizontal tokens={{ childrenGap: 8 }} verticalAlign="center" style={{ marginTop: -5 }}>
                                <IconButton iconProps={{ iconName: 'Settings' }} onClick={() => handleSherloqSettingPanel(true)} />

                                {isSherloqActive ? (
                                    <Text style={{ color: '#107C10', fontWeight: '600' }}>{IvicosStrings.sherloqConfigured}</Text>
                                ) : (
                                    <Text style={{ color: '#A80000', marginTop: -3, fontWeight: '600' }}>{IvicosStrings.sherloqNotConfigured}</Text>
                                )}
                            </Stack>
                        )}
                    </Stack>
                    {isSherloqDeactivationModalVisible && (
                        <SherloqDeactivationModal
                            isSherloqDeactivationModalVisible={isSherloqDeactivationModalVisible}
                            setIsSherloqDeactivationModalVisible={setIsSherloqDeactivationModalVisible}
                            setIsSherloqActive={setIsSherloqActive}
                        />
                    )}
                    <Panel
                        headerText="SHERLOQ"
                        isOpen={isSherloqSettingPanelOpen}
                        onDismiss={() => handleSherloqSettingPanel(false)}
                        closeButtonAriaLabel="Close"
                    >
                        <SherloqSettingsForm
                            setIsSherloqActive={setIsSherloqActive}
                            handleSherloqSettingPanel={handleSherloqSettingPanel}
                            sherloqSettingsFormData={sherloqSettingsFormData}
                            setSherloqSettingsFormData={setSherloqSettingsFormData}
                            listOfExamplePrompts={listOfExamplePrompts}
                            setListOfExamplePrompts={setListOfExamplePrompts}
                        />
                    </Panel>
                </>
            ) : (
                <Stack style={{ maxWidth: 400, alignItems: 'start' }} tokens={{ childrenGap: 32 }}>
                    <Text variant="xLarge">Loading...</Text>
                </Stack>
            )}
        </div>
    );
};
