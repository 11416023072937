import { useBoolean } from '@uifabric/react-hooks';
import { useEvent } from 'kits/eventKit';
import { useEffect, useState } from 'react';
import { idpService } from 'services/api/identity-provider.service';
import { useIsSideBarMaximized, useWindowWidth } from 'shared-state/display-options/hooks';
import { useIdentityClaims } from 'shared-state/identity/hooks';
import { IIdentityClaims } from 'shared-state/identity/types';
import { useCurrentAreaId, useCurrentRoomId } from 'shared-state/location/hook';

export interface IMainState {
    identityClaims: IIdentityClaims | undefined;
    currentRoomId: string | undefined;
    currentAreaId: string | undefined;
    onTabSelect: () => void;
    smallDevice: boolean;
    mobileMenuOpen: boolean;
    toggleAlert: () => void;
    displayAlert: boolean;
}

export const useMainState: () => IMainState = () => {
    const [identityClaims] = useIdentityClaims();

    const [currentRoomId] = useCurrentRoomId();
    const [currentAreaId] = useCurrentAreaId();

    const [, setSideBarIsMaximized] = useIsSideBarMaximized();

    const [displayAlert, { toggle: toggleAlert }] = useBoolean(false);

    const onTabSelect = () => setSideBarIsMaximized(true);

    const getSherloqSettingsData = async () => {
        const { is_sherloq_active, sherloq_settings } = await idpService.getSherloqSettings();
        const sherloqPrompts = sherloq_settings.example_prompts;
        console.log('Sherloq Settings Data', is_sherloq_active, sherloq_settings);
        localStorage.setItem('sherloqPrompts', JSON.stringify(sherloqPrompts));
        localStorage.setItem('isSherloqActive', JSON.stringify(is_sherloq_active));
    };

    useEffect(() => {
        if (!('Notification' in window)) {
            toggleAlert();
        } else {
            Notification.requestPermission();
        }
    }, []);

    useEffect(() => {
        getSherloqSettingsData();
    }, [currentRoomId, currentAreaId]);

    const { smallDevice } = useWindowWidth();

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    useEvent('closeMobileRoomsPanel', () => {
        setMobileMenuOpen(false);
    });
    useEvent('openMobileRoomsPanel', () => {
        setMobileMenuOpen(true);
    });

    return { identityClaims, currentRoomId, currentAreaId, onTabSelect, smallDevice, mobileMenuOpen, displayAlert, toggleAlert };
};
