import { Stack, Text } from '@fluentui/react'
import React, { useEffect } from 'react'


export const TextWidget: React.FC = (props) => {
    return (
        <Stack tokens={{ childrenGap: '8px' }}>
            <Text variant="xLarge">Text</Text>
            <Text>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque ipsam quis beatae dolore. Ipsa, officiis accusantium? Doloribus facilis facere commodi nemo, eveniet nesciunt quae. At quos illo illum quisquam blanditiis!</Text>
        </Stack>
    )

}